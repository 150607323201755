<template>
  <section v-if="license != null">
    <div class="mt-2">
          <b-card :title="$t('LICENSE.TITLE')">
            <b-card-text>
              <dl class="mt-2">
                <dt>{{ $t("CREATED") }}</dt>
                <dd>
                  {{
                    FormatDateTime(license.created)
                  }}
                </dd>
                <dt>{{ $t("HOSTNAME") }}</dt>
                <dd>{{ license.hostname }}&nbsp;</dd>
                <dt>{{ $t("TYPE") }}</dt>
                <dd>{{ showType }}&nbsp;</dd>

                <dt>{{ $t("LICENSE.LEVEL") }}</dt>
                <dd>{{ showLicense }}&nbsp;</dd>
                <dt>{{ $t("LICENSE.MULTITENANCY") }}</dt>
                <dd>{{ showMultitenancy }}&nbsp;</dd>
                <dt>{{ $t("LICENSE.ACCOUNTS") }}</dt>
                <dd>{{ license.total_accounts }}&nbsp;</dd>
                <dt>{{ $t("LICENSE.ACTIVE_ACCOUNTS") }}</dt>
                <dd>{{ license.active_accounts }}&nbsp;</dd>
              </dl>
            </b-card-text>
          </b-card>
    </div>
  </section>
</template>
<script>
export default {
  props: ["license"],
  data() {
    return {
      information: {},
      to_date: new Date(),
      from_date: this.getLastMonth()
    };
  },
  methods: {
    getLastMonth() {
      var fromDate = new Date();
      fromDate.setMonth(fromDate.getMonth() - 1);
      return fromDate;
    },
  },
  computed: {
    showType() {
      return this.$t("LICENSE." + this.license.type);
    },
    showMultitenancy() {
      return this.license.multitenant == 0 ? this.$t("NO") : this.$t("YES");
    },
    showLicense: function() {
      return this.$t("LICENSE.LEVELS." + this.license.level);
    },
  },
  mounted: function() {},
};
</script>
<style></style>
