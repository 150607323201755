var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.license != null
    ? _c("section", [
        _c(
          "div",
          { staticClass: "mt-2" },
          [
            _c(
              "b-card",
              { attrs: { title: _vm.$t("LICENSE.TITLE") } },
              [
                _c("b-card-text", [
                  _c("dl", { staticClass: "mt-2" }, [
                    _c("dt", [_vm._v(_vm._s(_vm.$t("CREATED")))]),
                    _c("dd", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.FormatDateTime(_vm.license.created)) +
                          "\n              "
                      ),
                    ]),
                    _c("dt", [_vm._v(_vm._s(_vm.$t("HOSTNAME")))]),
                    _c("dd", [_vm._v(_vm._s(_vm.license.hostname) + " ")]),
                    _c("dt", [_vm._v(_vm._s(_vm.$t("TYPE")))]),
                    _c("dd", [_vm._v(_vm._s(_vm.showType) + " ")]),
                    _c("dt", [_vm._v(_vm._s(_vm.$t("LICENSE.LEVEL")))]),
                    _c("dd", [_vm._v(_vm._s(_vm.showLicense) + " ")]),
                    _c("dt", [_vm._v(_vm._s(_vm.$t("LICENSE.MULTITENANCY")))]),
                    _c("dd", [_vm._v(_vm._s(_vm.showMultitenancy) + " ")]),
                    _c("dt", [_vm._v(_vm._s(_vm.$t("LICENSE.ACCOUNTS")))]),
                    _c("dd", [
                      _vm._v(_vm._s(_vm.license.total_accounts) + " "),
                    ]),
                    _c("dt", [
                      _vm._v(_vm._s(_vm.$t("LICENSE.ACTIVE_ACCOUNTS"))),
                    ]),
                    _c("dd", [
                      _vm._v(_vm._s(_vm.license.active_accounts) + " "),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }